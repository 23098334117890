<script lang="ts">
  import Button from '$lib/components/button/button.svelte';
  import CodeSnippet from '$lib/components/code-snippet/code-snippet.svelte';
  import Testimonial from '$lib/components/testimonial/testimonial.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { PromoBlock } from '$lib/contentful/models/promo-block';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';

  export let content: PromoBlock | undefined;

  const testimonialGridClasses = [
    '',
    'grid-cols-1',
    'max-xl:grid-cols-1 xl:grid-cols-2',
    'max-lg:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3',
  ];
</script>

{#if content}
  {@const {
    title,
    description,
    eyebrow,
    testimonials,
    codeSnippet,
    callsToAction,
    entityId,
    background,
    backgroundImage,
    bordered,
    slim,
    layout,
  } = content}
  <Grid
    {background}
    {bordered}
    {backgroundImage}
    as="div"
    layout="columns"
    class="no-padding gap-y-12 {slim
      ? 'max-md:py-8 md:py-20'
      : 'max-md:py-24 md:py-48 xl:py-80'}"
  >
    <Box
      as="div"
      class="flex flex-col flex-wrap gap-8  {layout === 'rows'
        ? 'items-center justify-center'
        : 'items-start'}"
      span={layout === 'columns' ? 4 : 10}
    >
      {#if eyebrow}
        <Text
          as="p"
          variant="eyebrow-16"
          {entityId}
          fieldId="eyebrow"
          {background}
        >
          {eyebrow}
        </Text>
      {/if}
      {#if title}
        <Text
          {entityId}
          fieldId="title"
          {background}
          variant={slim ? 'display-68' : 'display-128'}
        >
          {title}
        </Text>
      {/if}
      {#if description}
        <Text {background} {entityId} fieldId="description" variant="body-24">
          {description}
        </Text>
      {/if}
    </Box>
    <Box
      as="div"
      class="flex max-xl:flex-col xl:flex-row items-center gap-8 {layout ===
      'columns'
        ? 'max-xl:justify-center xl:justify-start'
        : 'justify-center'}"
      offset={layout === 'columns' ? 8 : 3}
      span={layout === 'columns' ? 6 : 10}
    >
      {#if testimonials}
        <div
          class="w-full justify-items-center grid gap-8 {testimonialGridClasses[
            Math.min(testimonials.length, 3)
          ]}"
        >
          {#each testimonials as testimonial (testimonial.entityId)}
            <Testimonial content={testimonial} />
          {/each}
        </div>
      {:else if codeSnippet}
        <CodeSnippet slim dark content={codeSnippet} />
      {:else if callsToAction?.length}
        {#each callsToAction as { text, entityId, ...rest } (entityId)}
          <Button {...rest}>{text}</Button>
        {/each}
      {/if}
    </Box>
  </Grid>
{/if}
